import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { API_URL } from "../../constants/urls";
import Swal from "sweetalert2";

const AddDevice = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [deviceId, setDeviceId] = useState("");
  const [parameter, setParameter] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState(false);
  const { user } = useSelector((state) => ({ ...state.auth }));
  const adminId = user?.id;

  const postDevice = async () => {
    let deviceInfo = { deviceId, userId: location.state.userId, adminId, parameter };
    let result = await fetch(`${API_URL}/add-device`, {
      method: "POST",
      body: JSON.stringify(deviceInfo),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (result.status === 400) {
      setErrMsg(true);
    }
    if (result.status === 200) {
      addDevice();
    }
    result = await result.json();
    setMessage(result.message);
  };

  const addDevice = () => {
    Swal.fire({
      title: "Device Added Successfully!",
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/devices-list");
      }
    });
  };

  return (
    <>
      <Sidebar
        element={
          <div className="container pb-5">
            <div className="row" style={{ paddingTop: "50px" }}>
              <div className="col-md-12">
                <h3 className="blue-text font_weight">Add Device</h3>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6 mx-auto">
                <form className="p-3 p-md-5 form_add">
                  {errMsg === true ? (
                    <div className="alert alert-danger text-center" role="alert">
                      {" "}
                      {message}{" "}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className=" mb-4">
                    <label for="floatingName">Device ID</label>
                    <input type="text" className="input_3" onChange={(e) => setDeviceId(e.target.value)} id="floatingName" placeholder="Device Id" />
                  </div>

                  <div className=" mb-4">
                    <label for="floatingPhone">Channels</label>
                    <input type="number" className="input_3" onChange={(e) => setParameter(e.target.value)} id="floatingPhone" placeholder="4" />
                  </div>
                  <div className="">
                    <button type="button" style={{ display: "block", width: "100%" }} className="btn-2 ms-auto submit-btn" onClick={postDevice}>
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default AddDevice;
