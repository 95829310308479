import React, { useState } from "react";
import "../Common/Common.css";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { setNewPassword } from "../../../slices/user";
import Swal from "sweetalert2";
import SignPage from "../PropsPage/SignPage";
const NewPassword = () => {
  let navigate = useNavigate();

  const { user } = useSelector((state) => ({ ...state.auth }));
  let { token } = useParams();
  const accessToken = token ? token : user?.accessToken;

  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [errMsg, setErrMsg] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const handleNewPassword = () => {
    if (!accessToken) {
      return;
    }
    const formData = {
      password,
      confirm,
    };

    dispatch(setNewPassword({ formData, accessToken }))
      .unwrap()
      .then((res) => {
        if (!token) {
          navigate("/my-profile");
        }
        if (res.user.status === false) {
          setErrMsg(true);
          setMessage(res.user.message);
        } else {
          Swal.fire({
            title: "Password Updated Successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="form-top">
        <div className="intro">
          <div className="row">
            <SignPage
              Head="Sign in to our platform"
              Pera=" The IoT portal offers secure login access for users to manage their devices and gather data seamlessly. Through the portal, users can remotely control device settings, monitor statuses, and access valuable data insights with ease. It serves as a centralized hub for optimizing device performance and enhancing user experience in the realm of connected technologies."
            />

            <div className="col-lg-6 col-sm-12 right_side">
              <div className="form_login">
                <div className="head_top">
                  <h1 className="font_weight">Set New Password</h1>
                  <p className="no-worrie-text">Your new passowrd must be different to previously used passwords</p>
                  {errMsg === true ? (
                    <div className="alert alert-danger py-2 mt-5 text-center" role="alert">
                      {message}
                    </div>
                  ) : (
                    ""
                  )}
                  <label className="form-label">New Password</label>
                  <input type="password" className="input_3" id="password" aria-describedby="emailHelp" placeholder="&#xf30d;  Enter your New Password" onChange={(e) => setPassword(e.target.value)} />

                  <br />
                  <br />
                  <label className="form-label">Confirm New Password</label>
                  <input
                    type="password"
                    className="input_3"
                    id="confirm-password"
                    aria-describedby="emailHelp"
                    placeholder="&#xf30d; Confirm your New Password"
                    onChange={(e) => setConfirm(e.target.value)}
                  />

                  <button type="button" className="btn-1 mb-4 mx-auto mt-4" onClick={handleNewPassword}>
                    RESET PASSWORD
                  </button>

                  <NavLink to="/login" className="link-text" style={{ color: "#ff0000" }}>
                    <p className="mb-5 mt-2 back-txt">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="15px">
                        <path
                          fill="#ff0000"
                          d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                        />
                      </svg>{" "}
                      Back to <span> Login </span>{" "}
                    </p>
                  </NavLink>
                </div>
              </div>
              <div className="Cradit">
                <a href="https://dreamcoder.in/" target="_blank">
                  <svg width="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      fill="#262b40"
                      d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"
                    />
                  </svg>{" "}
                  Design by DreamCoder
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
