import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../Sidebar/Sidebar";
import { API_URL } from "../../../constants/urls";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../Loader/Loader";

const Dealers = () => {
  const navigate = useNavigate();
  let [users, setUsers] = useState([]);
  let [refreshUser, setRefreshUser] = useState(false);
  let [message, setMessage] = useState(null);
  let [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => ({ ...state.auth }));
  const adminId = user?.id;
  useEffect(() => {
    const url = `${API_URL}/get-dealers/${adminId}`;

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        if (!response.ok) {
          setMessage(json.message);
        } else {
          setLoading(false);
          await setUsers(json.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [refreshUser]);

  const userDetails = (e, name) => {
    navigate("/users-list", { state: { userId: e, userName: name } });
  };

  const deleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${API_URL}/delete-user/${userId}`);
          if (response.status === 200) {
            Swal.fire("Deleted!", "Dealer has been deleted.", "success");
            setRefreshUser(true);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    });
  };
  const userUpdateDetails = (e) => {
    navigate("/edit-user", { state: { user: e, type: "Dealer" } });
  };
  return (
    <>
      <Sidebar
        element={
          <>
            {" "}
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="row mb-4 mt-5">
                    <div className="col-sm-6 ">
                      <h3 className="blue-text font_weight"> {user?.name}'s Dealers</h3>
                    </div>
                    <div className="col-sm-6">
                      <NavLink to="/add-dealer">
                        {" "}
                        <button type="button" style={{ display: "block" }} className="btn ms-auto btn-2 submit-btn ">
                          Add Dealer
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div classname="col-md-12">
                  {loading ? (
                    <Loader />
                  ) : (
                    <table className="table no-wrap table-hover mb-0 text-center">
                      <thead>
                        <tr>
                          <th scope="col" className="border-0 text-uppercase font-medium blue-text pl-4">
                            S.No
                          </th>
                          <th scope="col" className="border-0 text-uppercase font-medium blue-text">
                            Name
                          </th>
                          <th scope="col" className="border-0 text-uppercase font-medium blue-text">
                            Email
                          </th>
                          <th scope="col" className="border-0 text-uppercase font-medium blue-text">
                            Phone no.
                          </th>
                          <th scope="col" className="border-0 text-uppercase font-medium blue-text">
                            Status
                          </th>
                          <th scope="col" className="border-0 text-uppercase font-medium blue-text">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users &&
                          users.length > 0 &&
                          users.map((user, index) => {
                            return (
                              <>
                                <tr key={index} style={{ cursor: "pointer" }}>
                                  <td
                                    className="pl-4 grey-text"
                                    onClick={() => {
                                      userDetails(user._id, user.name);
                                    }}
                                  >
                                    {index + 1}
                                  </td>
                                  <td
                                    onClick={() => {
                                      userDetails(user._id, user.name);
                                    }}
                                  >
                                    <span className="font-medium mb-0 grey-text">{user.name}</span>
                                  </td>
                                  <td
                                    onClick={() => {
                                      userDetails(user._id, user.name);
                                    }}
                                  >
                                    <span className="font-medium grey-text">{user.email}</span>
                                  </td>
                                  <td
                                    onClick={() => {
                                      userDetails(user._id, user.name);
                                    }}
                                  >
                                    <span className="font-medium grey-text">{user.phone_no}</span>
                                  </td>
                                  <td
                                    onClick={() => {
                                      userDetails(user._id, user.name);
                                    }}
                                  >
                                    <span className="font-medium grey-text">{user.isSuspend === true ? "🟡 Suspended" : "🟢 Active"}</span>
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn blue-background text-white me-1"
                                      onClick={() => {
                                        userUpdateDetails(user._id);
                                      }}
                                    >
                                      <i className="fa-solid fa-edit"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => {
                                        deleteUser(user._id);
                                      }}
                                    >
                                      <i className="fa-solid fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  )}
                  {users.length <= 0 ? (
                    <div className="text-center mt-5">
                      <h1>{message}</h1>
                    </div>
                  ) : (
                    ""
                  )}
                </div>{" "}
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default Dealers;
